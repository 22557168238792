@use "@angular/material" as mat;

@import "~material-icons/iconfont/material-icons.css";

/* Base theme */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

//Disable scrolling on mobile
body {
  position: fixed;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

router-outlet + * {
  display: block;
  width: 100%;
  height: 100%;
  animation: fade 0.2s;
}

.center-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
}
